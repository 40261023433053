import * as React from 'react';
import * as Icon from '@ant-design/icons';
import { Input, Form, Table, Button, message, Upload } from 'antd';
import axios from 'axios';
import './index.css';

/**人工核销码 */
interface page {
    columns: any;
    dataSource: any;
    rowData: any;
    total: number;
    size: number;
    current: number;
    tableHeight: number
}
  
export default class ManualCodeCancellation extends React.Component<{ parent?: any }, page> {
    private formSearch: any = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    title: '核销码',
                    dataIndex: 'redeemCode',
                    key: 'redeemCode',
                    align: 'center'
                },
                {
                    title: '商家名称',
                    dataIndex: 'sellerName',
                    key: 'sellerName',
                    align: 'center'
                },
                {
                    title: '可核销时间起',
                    dataIndex: 'validateFrom',
                    key: 'validateFrom',
                    align: 'center'
                },
                {
                    title: '可核销时间止',
                    key: 'validateTo',
                    dataIndex: 'validateTo',
                    align: 'center'
                },
                {
                    title: '核销状态',
                    key: 'status',
                    dataIndex: 'status',
                    align: 'center',
                    render: (text, record, index) => {
                        return (
                            <div>
                                {text === 0 && '未核销'}
                                {text === 1 && '已核销'}
                            </div>
                        )
                    }
                },
                {
                    title: '核销时间',
                    key: 'validateTime',
                    dataIndex: 'validateTime',
                    align: 'center'
                }
            ],
            dataSource: [],
            rowData: {},
            total: 0,
            size: 10,
            current: 1,
            tableHeight: 300
        }
    }

    componentDidMount() {
        this.getData({ size: 10, current: 1 });
        this.tableAdapt();
        window.addEventListener('resize', this.tableAdapt);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.tableAdapt);
    }

    /**表格高度自适应 */
    tableAdapt = () => {
        let search: any = document.getElementsByClassName('search-container')[0].clientHeight + 225;
        this.setState({ tableHeight: search })
    }

    /**获取数据 */
    private async getData(data, searchData?) {
        axios({
            url: `${process.env.REACT_APP_REMOTE_URL}/mgr/mannual/redeemCode/list/${data.size}/${data.current}`,
            params: searchData,
            method: 'get',
            headers: { Authorization: sessionStorage.getItem("token") },
        }).then(res => {
            let ret = res.data;
            if (ret.success) {
                this.setState({
                    dataSource: ret.data.records,
                    total: ret.data.total,
                    size: ret.data.size,
                    current: ret.data.current,
                })
            } else {
                this.props.parent.verification(ret.errroMsg);
                message.error(ret.errroMsg);
            }
        })
    }

    /**分页切换*/
    handleTableChange = (pagination) => {
        this.setState({
            current: pagination.current,
            size: pagination.pageSize
        }, () => {
            this.getData({ size: this.state.size, current: this.state.current });
        })
    };

    /**查询 */
    onSearch = (value) => {
        for (let key in value) {
            if (value[key] === undefined || value[key] === "") {
                delete value[key]
            }
        }
        this.getData({ size: 10, current: 1 }, value)
    }
    onChange = (info) => {
        if (info.file.status === 'done') {
          if(info.file.response.success) {
                message.success('导入成功');
              this.getData({ size: 10, current: 1 });
          } else {
            message.error(`${info.file.response.errroMsg}`);
          }
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
    }
    // 下载导入模板
    handDownload = () => {
        let a = document.createElement("a");
        a.href = "https://proregards-public.oss-us-west-1.aliyuncs.com/dev/%E4%BA%BA%E5%B7%A5%E6%A0%B8%E9%94%80%E7%A0%81%E6%A8%A1%E6%9D%BF.xlsx";
        a.download = "人工核销码.xlsx";
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    public render() {
        return (
            <div className="container">
                {/* 搜索区域 */}
                <Form name="search" className="search-container" ref={this.formSearch} initialValues={{ name4: 'all', name5: 'all' }} onFinish={this.onSearch}>
                    <Form.Item label="核销码" name="redeemCode" className="search-item">
                        <Input allowClear />
                    </Form.Item>
                    <Form.Item label="商家" name="sellerName" className="search-item">
                        <Input allowClear />
                    </Form.Item>
                    <div className="search-btn">
                        <Button type="primary" htmlType="submit" icon={<Icon.SearchOutlined />} >查询</Button>
                        <Button type="primary" onClick={this.handDownload}>下载导入模板</Button>
                        <Upload
                            name='file'
                            accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            action={`${process.env.REACT_APP_REMOTE_URL}/mgr/mannual/redeemCode/doImport`}
                            showUploadList={false}
                            headers={
                                {
                                    Authorization: sessionStorage.getItem("token") || 'authorization-text'
                                }
                            }
                            onChange ={this.onChange}
                        >
                            <Button>导入</Button>
                        </Upload>
                    </div>
                </Form>

                {/* 表格区域 */}
                <div className="table-container">
                    <div className="tab-container">
                        <Table
                            columns={this.state.columns}
                            dataSource={this.state.dataSource}
                            rowKey={record => record.id}
                            pagination={{ pageSize: this.state.size, current: this.state.current, total: this.state.total, showTotal: (total) => `共 ${total} 条数据` }}
                            onChange={this.handleTableChange}
                            scroll={{ y: `calc(100vh - ${this.state.tableHeight}px)` }}
                            onRow={record => {
                                return {
                                    onClick: () => {
                                        this.setState({
                                            rowData: record
                                        })
                                    },
                                }
                            }}
                        />
                    </div>
                </div>
            </div >
        );
    }
}
