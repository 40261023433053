import * as React from 'react';
import * as Icon from '@ant-design/icons';
import { Input, Form, Table, Button, Select, message } from 'antd';
import axios from 'axios';
import './index.css';

const { Option } = Select;

/**卡券记录 */
interface page {
    columns: any;
    dataSource: any;
    visible: boolean;
    modelTitle: string;
    rowClass: string;
    rowData: any;
    loadings: boolean;
    total: number;
    size: number;
    current: number;
    pointsData: any;
    tableHeight: number,
}

export default class CardRecord extends React.Component<{ parent?: any }, page> {
    private formSearch: any = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    title: '卡券id',
                    dataIndex: 'code',
                    key: 'code',
                    align: 'center',
                    width: '50px',
                },
                {
                    title: '卡券名称',
                    dataIndex: 'card_name',
                    key: 'card_name',
                    align: 'center',
                    width: '80px',
                    render: (text, record, index) => {
                        return (
                            <span>{record.coupon.name}</span>
                        )
                    }
                },
                {
                    title: '关联商家',
                    dataIndex: 'relation',
                    key: 'relation',
                    align: 'center',
                    width: '80px',
                    render: (text, record, index) => {
                        return (
                            <div>
                                <div>
                                    <span>商家id:</span><span>{record.seller.id}</span>
                                </div>
                                <div>
                                    <span>商家名称:</span><span>{record.seller.name}</span>
                                </div>
                            </div>
                        )
                    }
                },
                // {
                //     title: '卡券内容',
                //     dataIndex: 'card_info',
                //     key: 'card_info',
                //     align: 'center',
                //     width: '200px',
                //     render: (text, record, index) => {
                //         return (
                //             <span>{record.coupon.description}</span>
                //         )
                //     }
                // },
                {
                    title: '活动',
                    key: 'activity',
                    dataIndex: 'activity',
                    align: 'center',
                    width: '150px',
                    render: (text, record, index) => {
                        return (
                            <div>
                                <div>
                                    <span>关联活动id:</span><span>{record.activityId}</span>
                                </div>
                                <div>
                                    <span>关联活动名称:</span><span>{record.activityName}</span>
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '领取人',
                    key: 'recipient',
                    dataIndex: 'recipient',
                    align: 'center',
                    width: '50px',
                    render: (text, record, index) => {
                        return (
                            <div>
                                <div>
                                     <span>{record.customer.name}</span>
                                </div>

                            </div>
                        )
                    }
                },
                {
                    title: '发放时间',
                    key: 'Time',
                    dataIndex: 'Time',
                    align: 'center',
                    width: '50px',
                    render: (text, record, index) => {
                        return (
                            <span>{record.createdTime}</span>
                        )
                    }
                },
                // {
                //     title: '领取时间',
                //     key: 'createdTime',
                //     dataIndex: 'createdTime',
                //     align: 'center',
                //     width: '180px',
                //     render: (text, record, index) => {
                //         return (
                //             <span>{record.coupon.createdTime}</span>
                //         )
                //     }
                // },
                // {
                //     title: '核销时间',
                //     key: 'modifiedTime',
                //     dataIndex: 'modifiedTime',
                //     align: 'center',
                //     width: '180px',
                // },
                {
                    title: '卡券状态',
                    key: 'status',
                    dataIndex: 'status',
                    align: 'center',
                    width: '50px',
                    render: (text, record, index) => {
                        if (text === 0) return <span className="c-red">未核销</span>
                        else if (text === 1) return <span>已核销</span>
                        else if (text === 2) return <span style={{ color: '#9e9e9e' }}>已失效</span>
                    }
                },
            ],
            dataSource: [],
            visible: false,
            modelTitle: '',
            rowClass: '',
            rowData: {},
            loadings: false,
            total: 0,
            size: 10,
            current: 1,
            pointsData: {},
            tableHeight: 300,
        }
    }

    componentDidMount() {
        this.getData({ size: 10, current: 1 });
        this.tableAdapt();
        window.addEventListener('resize', this.tableAdapt);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.tableAdapt);
    }

    /**表格高度自适应 */
    tableAdapt = () => {
        let search: any = document.getElementsByClassName('search-container')[0].clientHeight + 225;
        this.setState({ tableHeight: search })
    }

    /**获取数据 */
    private async getData(data, searchData?) {
        axios({
            url: `${process.env.REACT_APP_REMOTE_URL}/mgr/coupon/record/list/${data.size}/${data.current}`,
            params: searchData,
            method: 'get',
            headers: { Authorization: sessionStorage.getItem("token") },
        }).then(res => {
            let ret = res.data;
            if (ret.success) {
                this.setState({
                    dataSource: ret.data.records,
                    total: ret.data.total,
                    size: ret.data.size,
                    current: ret.data.current,
                })
            } else {
                this.props.parent.verification(ret.errroMsg);
                message.error(ret.errroMsg);
            }
        })
    }

    /**分页切换*/
    handleTableChange = (pagination) => {
        this.setState({
            current: pagination.current,
            size: pagination.pageSize
        }, () => {
            this.getData({ size: this.state.size, current: this.state.current });
        })
    };

    /**显示弹窗 */
    visiblePop(state, tit?) {
        this.setState({
            visible: state,
            modelTitle: tit
        })
    }

    /**查询 */
    onSearch = (value) => {
        for (let key in value) {
            if (value[key] === undefined || value[key] === "") {
                delete value[key]
            }
        }
        this.getData({ size: 10, current: 1 }, value)
    }

    /**重置方法 */
    onReset = () => {
        this.formSearch.current.resetFields();
        this.getData({ size: 10, current: 1 });
    }

    public render() {
        return (
            <div className="container">

                {/* 搜索区域 */}
                <Form name="search" className="search-container" ref={this.formSearch} initialValues={{ name4: 'all', name5: 'all' }} onFinish={this.onSearch}>
                    <Form.Item label="卡券id" name="code" className="search-item">
                        <Input allowClear />
                    </Form.Item>
                    <Form.Item label="领取人tk昵称" name="name" className="search-item">
                        <Input allowClear />
                    </Form.Item>
                    <Form.Item label="使用状态" name="status" className="search-item">
                        <Select allowClear placeholder="请选择">
                            <Option value="">全部</Option>
                            <Option value={Number(0)}>未核销</Option>
                            <Option value={Number(1)}>已核销</Option>
                            <Option value={Number(2)}>已失效</Option>
                        </Select>
                    </Form.Item>
                    <div className="search-btn">
                        <Button type="primary" htmlType="submit" icon={<Icon.SearchOutlined />} >查询</Button>
                        <Button icon={<Icon.ReloadOutlined />} onClick={this.onReset}>重置</Button>
                    </div>
                </Form>

                {/* 表格区域 */}
                <div className="table-container">

                    <div className="tab-container">
                        <Table
                            columns={this.state.columns}
                            dataSource={this.state.dataSource}
                            rowKey={record => record.id}
                            pagination={{ pageSize: this.state.size, current: this.state.current, total: this.state.total, showTotal: (total) => `共 ${total} 条数据` }}
                            onChange={this.handleTableChange}
                            scroll={{ y: `calc(100vh - ${this.state.tableHeight}px)`, x: 2000 }}
                            onRow={record => {
                                return {
                                    onClick: () => {
                                        this.setState({
                                            rowData: record
                                        })
                                    },
                                }
                            }}
                        />
                    </div>
                </div>

            </div >
        );
    }
}
