import axios from 'axios';
import { Modal, Button, Space, message } from "antd";


export enum EHostType {
    BKM = "bkm",
    NONE = "none",
}


const AxiosWrap: any = (type: EHostType) => {
    const request: any = axios.create({
        baseURL: "",
        timeout: 300000,
    });

    request.interceptors.request.use(
        function (config) {
            config.headers.Authorization = sessionStorage.getItem("token");
            config.baseURL = '${process.env.REACT_APP_REMOTE_URL}';
            return config;
        },
        function (error) {
            // 对请求错误做些什么
            return Promise.reject(error);
        }
    );

    return request;
};



export default class Request {

    public static async get(url: string) {
        try {
            let res = await axios.get(url, { headers: { Authorization: sessionStorage.getItem("token") } });
            return res.data;
        } catch (err:any) {
            return { success: false, message: err.message };
        }
    }

    public static async delete(url: string) {
        try {
            let res = await axios.delete(url, { headers: { Authorization: sessionStorage.getItem("token") } });
            return res.data;
        } catch (err:any) {
            return { success: false, message: err.message };
        }
    }
    public static async post(url: string, params: any) {
        try {
            let res = await axios.post(url, params, { headers: { Authorization: sessionStorage.getItem("token"), 'Content-Type': "application/x-www-form-urlencoded" } });
            return res.data;
        } catch (err:any) {
            return { success: false, message: err.message };
        }
    }
    public static async jsonPost(url: string, params: any) {
        try {
            let res = await axios.post(url, params, { headers: { Authorization: sessionStorage.getItem("token"), 'Content-Type': "application/json" } });
            return res.data;
        } catch (err:any) {
            return { success: false, message: err.message };
        }
    }


    public static async get1( url: string, params: any) {

        try {

            let res = await axios.get(url, { headers: { Authorization: sessionStorage.getItem("token") } ,params});
            return res.data;
        } catch (err:any) {
            return { success: false, message: err.message };
        }
    }


    public static async putOss( type: EHostType, url: string, params: any, contentType: string, cb ) {
        // try {
        const request: any = axios.create({
            baseURL: "",
            timeout: 300000,
        });
        let res = await AxiosWrap(type).put(url, params, {
            headers: {
                Authorization: sessionStorage.getItem("token"),
                // "Content-Type": "application/octet-stream",
                "Content-Type": contentType,
            },
            onUploadProgress: (progressEvent) => {
                let process = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
                cb(process);
                // arr[0].percent = process;
                // console.log(arr[0].percent);
            },
        });
        return res.data;
        // } catch (err) {
        //   return { success: false, message: err.message };
        // }
    }

}
