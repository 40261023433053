import * as React from 'react';
import * as Icon from '@ant-design/icons';
import { Input, Form, Table, Button, Modal, message } from 'antd';
import Request from '../../utils/Request';
import './index.css';
import axios from 'axios';
import UserAdd from './UserAdd';
import UserShow from './UserShow';
import UserEdit from './UserEdit';

/**账号管理 */
interface page {
    columns: any;
    dataSource: any;
    visible: boolean;
    modelTitle: string;
    rowClass: string;
    rowData: any;
    loadings: boolean;
    total: number;
    size: number;
    current: number;
    searchValue: any;
    tableHeight: number;
}

export default class UserManagement extends React.Component<{ parent?: any }, page> {
    private formSearch: any = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    title: '用户id',
                    dataIndex: 'id',
                    key: 'id',
                    align: 'center',
                },
                {
                    title: '姓名',
                    dataIndex: 'name',
                    key: 'name',
                    align: 'center',
                },
                {
                    title: '手机号码',
                    dataIndex: 'phone',
                    key: 'phone',
                    align: 'center',
                },
                {
                    title: '角色',
                    dataIndex: 'role',
                    key: 'role',
                    align: 'center',
                    render: (text, record, index) => {
                        if (text === 0) return <span>超级管理员</span>
                        else if (text === 1) return <span>管理员</span>
                        else if (text === 2) return <span>运营</span>
                    }
                },
                {
                    title: '账户状态',
                    key: 'status',
                    dataIndex: 'status',
                    align: 'center',
                    render: (text, record, index) => {
                        return (
                            <span>{text === 0 ? '关闭' : '正常'}</span>
                        )
                    }
                },
                {
                    title: '注册时间',
                    key: 'createdTime',
                    dataIndex: 'createdTime',
                    align: 'center',
                    width: '180px'
                },
                {
                    title: '操作',
                    key: 'room_code8',
                    dataIndex: 'room_code8',
                    align: 'center',
                    render: (text, record, index) => {
                        return (
                            <div className="table-col-btn">
                                <span onClick={() => this.visiblePop(true, '查看')}>查看</span>
                                <span onClick={() => this.visiblePop(true, '编辑')}>编辑</span>
                            </div>
                        )
                    }
                },
            ],
            dataSource: [

            ],
            visible: false,
            modelTitle: '',
            rowClass: '',
            rowData: {},
            loadings: false,
            total: 0,
            size: 10,
            current: 1,
            searchValue: {},
            tableHeight: 300,
        }
    }

    componentDidMount() {
        this.getData({ size: 10, current: 1 });
        this.tableAdapt();
        window.addEventListener('resize', this.tableAdapt);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.tableAdapt);
    }

    /**表格高度自适应 */
    tableAdapt = () => {
        let search: any = document.getElementsByClassName('search-container')[0].clientHeight + 265;
        this.setState({ tableHeight: search })
    }

    private async getData(data, searchData?) {
        axios({
            url: `${process.env.REACT_APP_REMOTE_URL}/mgr/account/list/${data.size}/${data.current}`,
            params: searchData,
            method: 'get',
            headers: { Authorization: sessionStorage.getItem("token") },
        }).then(res => {
            let ret = res.data;
            if (ret.success) {
                this.setState({
                    dataSource: ret.data.records,
                    total: ret.data.total,
                    size: ret.data.size,
                    current: ret.data.current,
                })
            } else {
                this.props.parent.verification(ret.errroMsg);
                message.error(ret.errroMsg);
            }
        })
    }

    /**分页切换*/
    handleTableChange = (pagination) => {
        this.setState({
            current: pagination.current,
            size: pagination.pageSize
        }, () => {
            this.getData({ size: this.state.size, current: this.state.current });
        })
    };

    /**显示弹窗 */
    visiblePop(state, tit?) {
        if (tit === '新增') {
            this.setState({
                rowData: {},
            })
        }
        this.setState({
            visible: state,
            modelTitle: tit
        })
    }

    /**加载页面 */
    listContent = () => {
        if (this.state.modelTitle === '查看') return <UserShow parent={this} />
        else if (this.state.modelTitle === '编辑') return <UserEdit parent={this} />
        else if (this.state.modelTitle === '新增') return <UserAdd parent={this} />
    }

    /**查询 */
    onSearch = (value) => {
        for (let key in value) {
            if (value[key] === undefined || value[key] === "") {
                delete value[key]
            }
        }
        this.getData({ size: 10, current: 1 }, value)
    }

    /**重置方法 */
    onReset = () => {
        this.formSearch.current.resetFields();
        this.getData({ size: 10, current: 1 });
    }

    /**提交 */
    onFinish = (value) => {
        let _this = this;
        this.setState({ loadings: true });
        Modal.confirm({
            title: '是否确认提交',
            icon: <Icon.ExclamationCircleOutlined />,
            centered: true,
            content: (<p>是否确认提交？</p>),
            async onOk() {
                let url = `${process.env.REACT_APP_REMOTE_URL}/mgr/account/saveOrUpdate`;
                if (_this.state.modelTitle === '编辑') {
                    value['id'] = _this.state.rowData.id
                }
                let ret = await Request.jsonPost(url, JSON.stringify(value));
                if (ret.success) {
                    _this.visiblePop(false);
                    _this.getData({ size: 10, current: 1 });
                    message.success('提交成功');
                    _this.setState({ loadings: false });
                } else {
                    message.error('提交失败');
                    _this.setState({ loadings: false });
                }
            },
        });
    }

    /**删除 */
    delUser = () => {
        let _this = this;
        Modal.confirm({
            title: '是否确认删除',
            icon: <Icon.ExclamationCircleOutlined />,
            centered: true,
            content: (<p>是否确认删除</p>),
            async onOk() {
                let url = `${process.env.REACT_APP_REMOTE_URL}/mgr/account/${_this.state.rowData.id}`;
                let ret = await Request.delete(url);
                if (ret.success) {
                    _this.getData({ size: 10, current: 1 });
                    message.success('删除成功');
                } else {
                    message.error('删除失败');
                }
            },
        });
    }

    public render() {
        return (
            <div className="container">

                {/* 搜索区域 */}
                <Form name="search" className="search-container" ref={this.formSearch} initialValues={{}} onFinish={this.onSearch}>
                    <Form.Item label="账号名称" name="name" className="search-item">
                        <Input allowClear />
                    </Form.Item>
                    <Form.Item label="手机号码" name="phone" className="search-item">
                        <Input allowClear />
                    </Form.Item>
                    <div className="search-btn ant-col-12">
                        <Button type="primary" htmlType="submit" icon={<Icon.SearchOutlined />} >查询</Button>
                        <Button icon={<Icon.ReloadOutlined />} onClick={this.onReset}>重置</Button>
                    </div>
                </Form>

                {/* 表格区域 */}
                <div className="table-container">
                    <div className="table-btn">
                        <Button type="primary" icon={<Icon.PlusCircleOutlined />} onClick={() => this.visiblePop(true, '新增')}>新增</Button>
                        {/* <Button type="primary" icon={<Icon.PlusCircleOutlined />} onClick={this.delUser}>删除</Button> */}
                    </div>
                    <div className="tab-container">
                        <Table
                            columns={this.state.columns}
                            dataSource={this.state.dataSource}
                            rowKey={record => record.id}
                            pagination={{ pageSize: this.state.size, current: this.state.current, total: this.state.total, showTotal: (total) => `共 ${total} 条数据` }}
                            onChange={this.handleTableChange}
                            scroll={{ y: `calc(100vh - ${this.state.tableHeight}px)` }}
                            onRow={record => {
                                return {
                                    onClick: () => {
                                        this.setState({
                                            rowData: record,
                                            rowClass: record.id
                                        })
                                    },
                                }
                            }}
                        />
                    </div>
                </div>

                <Modal
                    destroyOnClose={true}
                    title={this.state.modelTitle}
                    centered={true}
                    visible={this.state.visible}
                    maskClosable={false}
                    width='500px'
                    className="from-modal"
                    onCancel={() => {
                        this.visiblePop(false);
                    }}
                    footer={null}
                >
                    <Form name="form" className="form-container" initialValues={this.state.rowData} onFinish={this.onFinish}>
                        {this.listContent}
                    </Form>
                </Modal>

            </div >
        );
    }
}
