
import * as React from 'react';
import './index.css';

interface page {
}

export default class ActivityEntrance extends React.Component<{ parent?: any }, page> {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    public render() {
        return (
            <React.Fragment>
                <div className="QRCode">
                    {this.props.parent.state.rowData.posterInfo !== undefined&&this.props.parent.state.rowData.posterInfo !== null ? <img src={this.props.parent.state.rowData.posterInfo.qrCodeUrl} alt="" /> : <span>暂无图片</span>}
                </div>
                <div className="QRCode-tit">请用tiktok扫码</div>
            </React.Fragment>
        );
    }
}