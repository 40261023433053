import {Button, Form, DatePicker, Image, Input, message, Modal, Row, Col, Spin, Tabs, Upload, Progress} from "antd";
import {CloseCircleOutlined, CloseOutlined, PlusOutlined,} from "@ant-design/icons";

import React, {useEffect, useRef, useState} from "react";

import './index.css'
import axios from "axios";

// const handlerPrev = debounce();

export const AddTopic = (props: { onChange: any, title: string, data: Array<any>, sellerId: any }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [topics, setTopics] = useState(props.data);
    let querying = false;
    let sellerId = props.sellerId;

    const [data, setData] = useState([]);

    //debugger;


    useEffect(() => {
        if (showDialog) {
            query('');
        }
    }, [showDialog, topics])

    const query = (text: any) => {
        querying = true;

        axios({
            url: `${process.env.REACT_APP_REMOTE_URL}/mgr/activity/topic/search/${sellerId}`,
            //params: searchData,
            method: 'get',
            headers: {Authorization: sessionStorage.getItem("token")},
        }).then(res => {
            let ret = res.data;
            if (ret.success) {
                // this.setState({
                //   dataSource: ret.data.records,
                //   total: ret.data.total,
                //   size: ret.data.size,
                //   current: ret.data.current,
                // })
                //debugger;
                querying = false;
                setData(ret.data);

            } else {
                // this.props.parent.verification(ret.errroMsg);
                message.error(ret.errroMsg);
            }
        })


        //   fetch(process.env.REACT_APP_REMOTE_URL+"/mgr/activity/topic/search?keywords="+text,
        //       {headers:{Authorization: util.getToken()}})
        //       .then(res=>util.handleResponse(res) )
        //       .then((data:any)=>{
        //         querying = false;
        //         setData(data);
        //       })
        //       .catch(()=>{querying=false});
        // }


    }

    const select = (topic: any) => {
        let existing = topics.find((item) => item.id == topic.id)
        if (!existing) {
            let newTopics = [...topics, topic];
            setTopics(newTopics);
            props.onChange(newTopics);
        }
        setShowDialog(false);
    };

    const deleteTopic = (value: any) => {
        let newTopics = [];
        for (let item of topics) {
            if (item.id != value) {
                // @ts-ignore
                newTopics.push(item);
            }
        }
        props.onChange(newTopics);
        setTopics(newTopics);
    }

    const getTopicStr = () => {
        let resultArr = [];
        for (let item of topics) {
            // @ts-ignore
            resultArr.push(item.id);
        }
        return resultArr.join(',');
    }

    return (
    <div>

        {topics.length > 0 && <span className="space-text">
            {topics.map((topic: any) => <span className="topic-item">#{topic.name}
                <span className="topic-remove">
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        deleteTopic(topic.id)
                    }}>
                        <CloseOutlined style={{fontSize: '14px', color: '#2D49FE'}}/>
                    </a>
                </span>&nbsp;&nbsp;&nbsp;</span>)}
        </span>}

        <span>
            <button className="bkm-btn-fill text-danger"
                    style={{background: "#ffebeb", border: "1px dotted #ff1e1e"}}
                    type="button" onClick={() => setShowDialog(true)}>#Add Topic
            </button>
        </span>

        {showDialog && <div className="overlay-full">
            <div className="topic-list">
                {data && data.map((item: any) => <div>
                    <div onClick={() => select(item)}>#{item.name}</div>
                </div>)}
            </div>
        </div>}
    </div>
    )


}

