import * as React from 'react';
import { Input, Form, Button, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Request from '../../utils/Request';

interface page {
    loadings: boolean;
}
export default class AccountRecharge extends React.Component<{ parent?: any }, page> {
    constructor(props) {
        super(props);
        this.state = {
            loadings: false
        }
    }

    onFinish = (value) => {
        let _this = this;
        this.setState({ loadings: true });
        Modal.confirm({
            title: '确认提示',
            icon: <ExclamationCircleOutlined />,
            centered: true,
            content: (<p>是否确认充值</p>),
            async onOk() {
                let url = `${process.env.REACT_APP_REMOTE_URL}/mgr/seller/recharge`;
                let pointAdd = {
                    id: _this.props.parent.state.rowClass,
                    points: value.pointAdd,
                    outTradeNo: value.outTradeNo,
                }
                value = pointAdd;
                let ret = await Request.jsonPost(url, JSON.stringify(value));
                if (ret.success) {
                    _this.props.parent.getData({ size: 10, current: 1 });
                    message.success('提交成功');
                    _this.setState({ loadings: false });
                    _this.props.parent.visiblePop(false);
                } else {
                    message.error(ret.errroMsg);
                    _this.props.parent.props.parent.verification(ret.errroMsg);
                    _this.setState({ loadings: false });
                }
            },
        });
    }

    public render() {
        return (
            <Form name="form" className="form-container" initialValues={this.props.parent.state.rowData} onFinish={this.onFinish}>

                <Form.Item label="累计充点" className="form-item ant-col-23" name="totalPoints">
                    <Input className="del-border" />
                </Form.Item>

                <Form.Item label="剩余点数" className="form-item ant-col-23" name="points" >
                    <Input className="del-border" />
                </Form.Item>

                <Form.Item label="点数充值" className="form-item ant-col-23" name="pointAdd" rules={[{ required: true, message: '点数充值不能为空！' }]}>
                    <Input allowClear placeholder="请输入正整数" />
                </Form.Item>

                <Form.Item label="付款订单号" className="form-item ant-col-23" name="outTradeNo" rules={[{ required: true, message: '付款订单号不能为空！' }]}>
                    <Input allowClear placeholder="请输入关联的付款订单号" />
                </Form.Item>

                <div className="form-btn">
                    <Button type="primary" htmlType="submit">提交</Button>
                    <Button onClick={() => this.props.parent.visiblePop(false)}>取消</Button>
                </div>

            </Form>
        );
    }
}