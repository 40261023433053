import * as React from 'react';
import * as Icon from '@ant-design/icons';
import { Menu, Dropdown, Modal, message } from 'antd';
import ModuleManage from '../modules/ModuleManage'
import './MainFrame.css';
import Request from '../utils/Request';

interface Props {
    match: any;
    history: any;
}
interface State {
    stretchState: boolean;
    moduleName: string;
    role: number;
    userName: any;
    isData: boolean;
    queryId: string;
    isNavClick: boolean;
}
export default class MainFrame extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            stretchState: false,
            moduleName: 'AgentManagement',
            role: 0,
            userName: '',
            isData: false,
            queryId: '',
            isNavClick: true,
        };
    }

    UNSAFE_componentWillMount() {
        let role = Number(sessionStorage.getItem("role"));
        this.setState({
            role: role,
            moduleName: role === 0 ? 'OperationConfiguration' : 'AgentManagement',
            userName: sessionStorage.getItem("userName")
        })
    }

    /**左侧菜单收起 */
    getStretchState() {
        if (this.state.stretchState === true) {
            this.setState({
                stretchState: false
            })
        } else {
            this.setState({
                stretchState: true
            })
        }
    }

    /**token验证 */
    verification(text) {
        if (text === '令牌验证失败,无权限用户禁止访问' || text === '请传入合法的Token') {
            this.props.history.push("/");
        }
    }

    /**传id查询 */
    isData(state?, data?) {
        this.setState({
            isData: state
        })
        if (data !== undefined) {
            this.setState({
                queryId: data.id
            }, () => {
                if (data.type === 'businessId') {
                    this.handleClick({ key: 'ActivityManagement' });
                } else if (data.type === 'agentId') {
                    this.handleClick({ key: 'BusinessManagement' });
                } else if (data.type === 'videoId') {
                    this.handleClick({ key: 'VideoReleaseRecord' });
                }
            })
        }
    }

    /**菜单切换 */
    handleClick = e => {
        if (this.state.isNavClick) {
            this.setState({
                moduleName: e.key,
                isNavClick: false,
            }, () => {
                setTimeout(() => {
                    this.setState({ isNavClick: true })
                }, 200)
            })
        }
    };

    /**退出登录 */
    private loginOut = async () => {
        let me = this;
        Modal.confirm({
            icon: <Icon.ExclamationCircleOutlined />,
            content: '是否确认退出系统？',
            centered: true,
            okText: '是',
            cancelText: '否',
            maskClosable: true,
            async onOk() {
                let url = `${process.env.REACT_APP_REMOTE_URL}/mgr/logout`;
                let ret = await Request.post(url, '');
                if (ret.success) {
                    message.success('退出系统成功!');
                    me.props.history.push("/");
                    sessionStorage.removeItem("token");
                } else {
                    message.error(ret.errroMsg);
                }
            },
        });
    }

    public render() {
        const { stretchState, role, moduleName } = this.state;
        return (
            <div className="wrapper">
                <div className="head">
                    <div className="head-title">
                        <div className="logo">【运营端】</div>
                    </div>
                    <div className="head-stretch" onClick={this.getStretchState.bind(this)}>
                        {stretchState === true ? <Icon.MenuUnfoldOutlined /> : <Icon.MenuFoldOutlined />}
                    </div>
                    <Dropdown trigger={['click']} overlay={
                        <div className="head-user-info">
                            {/* <span onClick={() => this.changePasswordModule(true)} ><Icon.LockOutlined />修改密码</span> */}
                            <span onClick={this.loginOut}><Icon.ExportOutlined />退出系统</span>
                        </div>}>
                        <div className="head-user">
                            <div className="user-img">
                                <span></span>
                            </div>
                            <div className="user-name">
                                {/* {this.state.userInfo.name} */}
                                {this.state.userName}
                            </div>
                            <div className="icon"><Icon.DownOutlined /></div>
                        </div>
                    </Dropdown>
                </div>

                <div className={stretchState === true ? "nav active" : "nav"}>
                    <Menu onClick={this.handleClick} selectedKeys={[moduleName]} defaultSelectedKeys={[moduleName]} mode="inline" >
                        {role === 1 || role === 2 ? <Menu.Item icon={<span className="iconfont icon-shangjia2"></span>} key="BusinessManagement">商家管理</Menu.Item> : null}
                        {role === 1 || role === 2 ? <Menu.Item icon={<span className="iconfont icon-liwu"></span>} key="ActivityManagement">活动管理</Menu.Item> : null}
                        {role === 1 || role === 2 ? <Menu.Item icon={<span className="iconfont icon-huati"></span>} key="TopicManagement">话题管理</Menu.Item> : null}
                        {role === 1 || role === 2 ? <Menu.Item icon={<span className="iconfont icon-shipin"></span>} key="VideoReleaseRecord">视频发布记录</Menu.Item> : null}
                        {role === 1 || role === 2 ? <Menu.Item icon={<span className="iconfont icon-quan"></span>} key="CardRecord">卡券记录</Menu.Item> : null}
                        {role === 1 ? <Menu.Item icon={<span className="iconfont icon-zhanghao"></span>} key="UserManagement">账号管理</Menu.Item> : null}
                        {role === 1 || role === 2 ? <Menu.Item icon={<span className="iconfont icon-daili2"></span>} key="ManualCodeCancellation">人工核销码</Menu.Item> : null}
                    </Menu>
                </div>

                <div className={stretchState === true ? "section active" : "section"}>
                    {ModuleManage.initModuleClass(this.state.moduleName, this)}
                </div>
            </div>
        );
    }
}