
import * as React from 'react';
import { Input, Form, Button, Radio, Select } from 'antd';
import './index.css';

const { Option } = Select;

interface page {
}

export default class VideoShow extends React.Component<{ parent?: any }, page> {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    public render() {
        return (
            <React.Fragment>
                <div className="video-box">
                    <div className="video-show">
                        {this.props.parent.state.rowData.videoUrl !== undefined || this.props.parent.state.rowData.videoUrl !== null ?
                            <video src={this.props.parent.state.rowData.videoUrl} controls></video> : <span>暂无视频</span>}
                    </div>
                    <div className="video-pop-close">
                        <Button onClick={() => this.props.parent.visiblePop(false)}>关闭</Button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}