
import * as React from 'react';
import {Input, Form, Button, Select, Radio, DatePicker, Upload, Modal, message, Cascader} from 'antd';
import './index.css';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import address from '../../assets/js/area.json';
import Request from '../../utils/Request';
import reqwest from 'reqwest';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;

interface page {
    tabState: boolean;
    previewVisible: boolean;
    previewImage: string;
    previewTitle: string;
    fileList: any;
    fileListShow: any;
    province: string;
    city: string
    county: string;
    agentData: any;
    uploading: boolean;
    parentState: number;
    options:any
}
export default class MerchantAdd extends React.Component<{ parent?: any }, page> {
    constructor(props) {
        super(props);
        this.state = {
            tabState: true,
            previewVisible: false,
            previewImage: '',
            previewTitle: '',
            fileList: [],
            fileListShow: [],
            province: '',
            city: '',
            county: '',
            agentData: null,
            uploading: false,
            parentState: 1,
            options:address
        }
    }

    componentDidMount() {

    }





    handleCancel = () => this.setState({ previewVisible: false });

    handleChange = ({ fileList }) => this.setState({ fileListShow: fileList })


    onFinish = (value) => {
        for (let key in value) {
            if (value[key] === undefined || value[key] === "") {
                delete value[key]
            }
        }
        value['agentId'] = 0;
        value['startTime'] = moment(value.startTime).format('YYYY-MM-DD HH:mm');
        value['endTime'] = moment(value.endTime).format('YYYY-MM-DD HH:mm');
        value['province']=value.province;
        value['city']=value.city;
        const formData = new FormData();
        this.state.fileList.forEach(file => {
            formData.append('logo', file);
        });
        this.setState({
            uploading: true,
        });
        for (let key in value) {
            formData.append(key, value[key]);
        }
        let _this = this;
        Modal.confirm({
            title: '是否确认提交',
            icon: <ExclamationCircleOutlined />,
            centered: true,
            content: (<p>是否确认提交？</p>),
            async onOk() {
                reqwest({
                    url: `${process.env.REACT_APP_REMOTE_URL}/mgr/seller/saveOrUpdate`,
                    method: 'post',
                    processData: false,
                    data: formData,
                    headers: { 'Authorization': sessionStorage.getItem("token") },
                    success: (ret) => {
                        if (ret.success) {
                            _this.props.parent.visiblePop(false);
                            _this.props.parent.getData({ size: 10, current: 1 });
                            message.success('提交成功');
                            _this.props.parent.setState({ loadings: false });
                            _this.props.parent.findParentSellerByName();
                        } else {
                            message.error(ret.errroMsg);
                            _this.props.parent.props.parent.verification(ret.errroMsg);
                            _this.props.parent.setState({ loadings: false });
                        }
                    }
                })
            }
        })
    };

    parentChange = (value) => {
        this.setState({
            parentState: value.target.value
        })
    }

    public render() {
        const { fileList } = this.state;
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );
        const props = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };

        return (
            <Form name="form" className="form-container" initialValues={this.props.parent.state.rowData} onFinish={this.onFinish}>

                <div className="form-content MerchantAdd">

                    <div className="form-titlt">商家基本信息</div>

                    <Form.Item label="商户名称" className="form-item ant-col-11" name="name" rules={[{ required: true, message: '商户名称不能为空！' }]}>
                        <Input allowClear placeholder="最多显示20个字" />
                    </Form.Item>

                    <Form.Item label="所属分类" className="form-item ant-col-11" name="categoryId" rules={[{ required: true, message: '所属分类不能为空！' }]}>
                        <Select  placeholder="请选择商户分类">
                            {this.props.parent.state.categoryData?.map((e, i) => {
                                return (
                                    <Option key={e.value + 'categoryId'} value={e.value}>{e.name}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>


                    <Form.Item label="商户负责人" className="form-item ant-col-11" name="realName" rules={[{ required: true, message: '商户负责人不能为空！' }]}>
                        <Input allowClear />
                    </Form.Item>

                    <Form.Item label="手机号码" className="form-item ant-col-11" name="phone" rules={[{ required: true, message: '手机号码不能为空！' }]}>
                        <Input allowClear placeholder="将作为商户登录账号使用" />
                    </Form.Item>



                   <Form.Item label="商家所在省" className="form-item ant-col-11" name="province">
                       <Input allowClear />
                   </Form.Item>

                    <Form.Item label="商家所在市" className="form-item ant-col-11" name="city">
                        <Input allowClear />
                    </Form.Item>

                    <Form.Item label="详细地址" name="address" className="form-item">
                        <TextArea placeholder="详细地址"  className="addresstextara"/>
                    </Form.Item>



                    <Form.Item label="登录密码" className="form-item ant-col-11" >
                        <Input disabled placeholder="账号创建后随机生成" />
                    </Form.Item>

                    <Form.Item label="商户有效期" required={true} className="form-item flex-time ant-col-22"  >
                        <Form.Item name="startTime" rules={[{ required: true, message: '开始时间不能为空！' }]}>
                            <DatePicker placeholder="开始时间" showTime format="YYYY-MM-DD HH:mm" />
                        </Form.Item>
                        <Form.Item name="endTime" rules={[{ required: true, message: '到期时间不能为空！' }]}>
                            <DatePicker placeholder="到期时间" showTime format="YYYY-MM-DD HH:mm" />
                        </Form.Item>
                    </Form.Item>

                    <Form.Item label="商户logo" className="form-item img">
                        <Upload
                            {...props}
                            listType="picture-card"
                            fileList={this.state.fileListShow}
                            onChange={this.handleChange}
                        >
                            {this.state.fileList.length >= 1 ? null : uploadButton}
                        </Upload>
                    </Form.Item>


                </div>

                <div className="form-btn">
                    <Button type="primary" htmlType="submit">提交</Button>
                    <Button onClick={() => this.props.parent.visiblePop(false)}>取消</Button>
                </div>

            </Form>
        );
    }
}