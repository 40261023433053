import * as React from 'react';
import * as Icon from '@ant-design/icons';
import { Input, Form, Table, Button, Select, Modal, message, DatePicker } from 'antd';
import './index.css';
import MerchantAdd from './MerchantAdd';            //添加商户
import MerchantShow from './MerchantShow';          //添加商户
import AccountRecharge from './AccountRecharge';    //账户充点
import MaterialLibrary from './MaterialLibrary';    //素材管理
import moment from 'moment';
import address from '../../assets/js/area.json';
import axios from 'axios';

const { Option } = Select;

/**商家管理 */
interface page {
    columns: any;
    dataSource: any;
    visible: boolean;
    modelTitle: string;
    rowClass: string;
    rowData: any;
    loadings: boolean;
    total: number;
    size: number;
    current: number;
    searchValue: any;
    popWidth: string;
    pointsData: any;
    city: any;
    county: any;
    agentData: any;
    categoryData: any;
    tableHeight: number;
    companyData: any;
}

export default class BusinessManagement extends React.Component<{ parent?: any }, page> {
    private formSearch: any = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    title: '商家id',
                    dataIndex: 'id',
                    key: 'id',
                    align: 'center',
                    width: '80px',
                },
                {
                    title: '商户名称',
                    dataIndex: 'name',
                    key: 'name',
                    align: 'left',
                    width: '200px',
                    render: (text, record, index) => {
                        if (record.sellerType === 1) return <span>{text}</span>
                        else if (record.sellerType === 2) return <div><span className="company-tip blue">连锁总部</span>{text}</div>
                        else if (record.sellerType === 3) return <div><span className="company-tip orange">连锁门店</span>{text}</div>
                    }
                },
                {
                    title: '负责人',
                    dataIndex: 'realName',
                    key: 'realName',
                    align: 'center',
                    width: '150px',
                    render: (text, record, index) => {
                        return (
                            <div>
                                <div>{record.realName}</div>
                                <div>{record.phone}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '所在地区',
                    key: 'region',
                    dataIndex: 'address',
                    align: 'address',
                    width: '180px',
                    render: (text, record, index) => {
                        let province, city, county;
                        address.map(e => {
                            if (e.value === record.province) {
                                province = e.label
                                e.children?.map(e => {
                                    if (e.value === record.city) {
                                        city = e.label
                                        e.children?.map(e => {
                                            if (e.value === record.county) {
                                                county = e.label
                                            }
                                            return true
                                        })
                                    }
                                    return true
                                })
                            }
                            return true
                        })
                        return (
                            <span>{province}{city}{county}</span>
                        )
                    }
                },
                {
                    title: '商户分类',
                    key: 'categoryId',
                    dataIndex: 'categoryId',
                    align: 'center',
                    width: '100px',
                    render: (text, record, index) => {
                        let texthtml;
                        this.state.categoryData.forEach(e => {
                            if (e.value === text) texthtml = e.name
                        })
                        return (
                            <span>{texthtml}</span>
                        )
                    }
                },

                {
                    title: '账户点数',
                    key: 'user_point',
                    dataIndex: 'user_point',
                    align: 'center',
                    width: '150px',
                    render: (text, record, index) => {
                        return (
                            <div>
                                <div>
                                    <span>累计充点:</span>
                                    <span>{record.totalPoints}</span>
                                </div>
                                <div>
                                    <span>剩余点数:</span>
                                    <span>{record.points}</span>
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '服务时间',
                    key: 'createdTime',
                    dataIndex: 'createdTime',
                    align: 'center',
                    width: '200px',
                    render: (text, record, index) => {
                        return (
                            <div>
                                <div>{record.createdTime}</div>
                                <div>~</div>
                                <div>{record.expired}</div>
                            </div>
                        )
                    }
                },
                // {
                //     title: '到期时间',
                //     key: 'expired',
                //     dataIndex: 'expired',
                //     align: 'center',
                //     width: '115px',
                // },
                {
                    title: '商户状态',
                    key: 'status',
                    dataIndex: 'status',
                    align: 'center',
                    width: '100px',
                    render: (text, record, index) => {
                        return (
                            <span style={{ color: `${text === 0 ? '#9e9e9e' : ''}` }}>{text === 0 ? '已过期' : '正常'}</span>
                        )
                    }
                },
                {
                    title: '操作',
                    key: 'btn',
                    dataIndex: 'btn',
                    width: '300px',
                    align: 'center',
                    fixed: 'right',
                    render: (text, record, index) => {
                        return (
                            <div className="table-col-btn">
                                <span onClick={() => this.visiblePop(true, '商家信息', '1000px')}>商家信息</span>
                                {record.sellerType===2?null:<span onClick={() => this.goActivity(record.id)}>关联活动</span>}
                                {record.sellerType===2?null:<span onClick={() => this.visiblePop(true, '账户充点', '500px')}>充点数</span>}
                            </div>
                        )
                    }
                },
            ],
            dataSource: [],
            visible: false,
            modelTitle: '',
            rowClass: '',
            rowData: {},
            loadings: false,
            total: 0,
            size: 10,
            current: 1,
            searchValue: {},
            popWidth: '1000px',
            pointsData: {},
            city: [],
            county: [],
            agentData: null,
            categoryData: [
                { name: '餐饮美食', value: 1 },
                { name: '休闲娱乐', value: 2 },
                { name: '美容美发', value: 3 },
                { name: '教育培训', value: 4 },
                { name: '酒店公寓', value: 5 },
                { name: '体育健身', value: 6 },
                { name: '景点乐园', value: 7 },
                { name: '洗浴足疗', value: 8 },
                { name: '生活服务', value: 9 },
                { name: '其他', value: 0 },
            ],
            tableHeight: 300,
            companyData: [],
        }
    }

    UNSAFE_componentWillMount() {
        this.findParentSellerByName();
    }

    componentDidMount() {
        if (this.props.parent.state.isData) {
            this.getData({ size: 10, current: 1 }, { agentId: this.props.parent.state.queryId });
        } else {
            this.getData({ size: 10, current: 1 });
        }
        this.tableAdapt();
        window.addEventListener('resize', this.tableAdapt);
    }

    componentWillUnmount() {
        this.props.parent.isData(false);
        window.removeEventListener('resize', this.tableAdapt);
    }

    /**表格高度自适应 */
    tableAdapt = () => {
        let search: any = document.getElementsByClassName('search-container')[0].clientHeight + 265;
        this.setState({ tableHeight: search })
    }

    /**获取数据 */
    private async getData(data, searchData?) {
        axios({
            url: `${process.env.REACT_APP_REMOTE_URL}/mgr/seller/list/${data.size}/${data.current}`,
            params: searchData,
            method: 'get',
            headers: { Authorization: sessionStorage.getItem("token") },
        }).then(res => {
            let ret = res.data;
            if (ret.success) {
                this.setState({
                    dataSource: ret.data.records,
                    total: ret.data.total,
                    size: ret.data.size,
                    current: ret.data.current,
                })
            } else {
                this.props.parent.verification(ret.errroMsg);
                message.error(ret.errroMsg);
            }
        })
    }

    /**分页切换*/
    handleTableChange = (pagination) => {
        this.setState({
            current: pagination.current,
            size: pagination.pageSize
        }, () => {
            this.getData({ size: this.state.size, current: this.state.current });
        })
    };

    /**代理商家 */
    goActivity = (id) => {
        this.props.parent.isData(true, { type: 'businessId', id: id });
    }

    /**显示弹窗 */
    visiblePop(state, tit?, width?) {
        if (tit === '账户充点') {
            this.setState({
                rowData: this.state.rowData.agent
            })
        } else if (tit === '添加商户') {
            this.setState({
                rowData: {}
            })
        }else  {
            if(this.state.rowData){
                this.setState({
                    rowData: this.state.rowData.id
                })
            }
        }
        this.setState({
            popWidth: width,
            visible: state,
            modelTitle: tit
        })
    }

    listContent = (text) => {
        if (text === '添加商户') {
            return (<MerchantAdd parent={this} />)
        } else if (text === '商家信息') {
            return (<MerchantShow parent={this} />)
        } else if (text === '账户充点') {
            return (<AccountRecharge parent={this} />)
        } else {
            return (<MaterialLibrary parent={this} />)
        }
    }

    /**查询 */
    onSearch = (value) => {
        value['startDate'] = value.startDate !== undefined ? moment(value.startDate).format('YYYY-MM-DD') : '';
        value['endDate'] = value.endDate !== undefined ? moment(value.endDate).format('YYYY-MM-DD') : '';
        for (let key in value) {
            if (value[key] === undefined || value[key] === "") {
                delete value[key];
            }
        }
        this.getData({ size: 10, current: 1 }, value);
    }

    /**重置方法 */
    onReset = () => {
        this.formSearch.current.resetFields();
        this.getData({ size: 10, current: 1 });
    }

    /**关联总公司数据 */
    findParentSellerByName() {
        axios({
            url: `${process.env.REACT_APP_REMOTE_URL}/mgr/seller/findParentSellerByName`,
            method: 'get',
            headers: { Authorization: sessionStorage.getItem("token") },
        }).then(res => {
            let ret = res.data;
            this.setState({
                companyData: ret.data
            })
        })
    }

    public render() {
        return (
            <div className="container">

                {/* 搜索区域 */}
                <Form name="search" className="search-container" ref={this.formSearch} initialValues={{ name4: 'all', name5: '选择' }} onFinish={this.onSearch}>
                    <Form.Item label="商家名称" name="name" className="search-item">
                        <Input allowClear />
                    </Form.Item>
                    <Form.Item label="手机号码" name="phone" className="search-item">
                        <Input allowClear />
                    </Form.Item>
                    {/*<Form.Item label="所在地区" name="province" className="search-item">*/}
                    {/*    <Select allowClear>*/}
                    {/*        {address.map(e => {*/}
                    {/*            return (*/}
                    {/*                <Option key={e.value} value={e.value}>{e.label}</Option>*/}
                    {/*            )*/}
                    {/*        })}*/}
                    {/*    </Select>*/}
                    {/*</Form.Item>*/}
                    <Form.Item label="商户分类" name="categoryId" className="search-item">
                        <Select allowClear placeholder="请选择商户分类">
                            {this.state.categoryData?.map(e => {
                                return (
                                    <Option key={e.value + 'type'} value={e.value}>{e.name}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <div className="search-btn ant-col-24">
                        <Button type="primary" htmlType="submit" icon={<Icon.SearchOutlined />} >查询</Button>
                        <Button icon={<Icon.ReloadOutlined />} onClick={this.onReset}>重置</Button>
                    </div>
                </Form>

                {/* 表格区域 */}
                <div className="table-container">
                    <div className="table-btn">
                        <Button type="primary" icon={<Icon.PlusCircleOutlined />} onClick={() => this.visiblePop(true, '添加商户', '1000px')}>添加商户</Button>
                    </div>
                    <div className="tab-container">
                        <Table
                            columns={this.state.columns}
                            dataSource={this.state.dataSource}
                            rowKey={record => record.id}
                            pagination={{ pageSize: this.state.size, current: this.state.current, total: this.state.total, showTotal: (total) => `共 ${total} 条数据` }}
                            onChange={this.handleTableChange}
                            scroll={{ x: 1800, y: `calc(100vh - ${this.state.tableHeight}px)` }}
                            onRow={record => {
                                return {
                                    onClick: () => {
                                        let data: any = record;
                                        data['startTime'] = moment(data.startDate, 'YYYY-MM-DD HH:mm');
                                        data['endTime'] = moment(data.expired, 'YYYY-MM-DD HH:mm');
                                        data['agentId'] = record.agent !== undefined ? record.agent.id : null;
                                        this.setState({
                                            rowData: data,
                                            pointsData: record.agent,
                                            rowClass: record.id,
                                        })
                                    },
                                }
                            }}
                        />
                    </div>
                </div>

                <Modal
                    destroyOnClose={true}
                    title={this.state.modelTitle}
                    centered={true}
                    visible={this.state.visible}
                    maskClosable={false}
                    width={this.state.popWidth}
                    className="from-modal"
                    onCancel={() => {
                        this.visiblePop(false);
                    }}
                    footer={null}>
                    <React.Fragment>
                        {this.listContent(this.state.modelTitle)}
                    </React.Fragment>
                    {/* <Form name="form" className="form-container" initialValues={this.state.rowData} onFinish={this.onFinish}>
                        {this.listContent}
                    </Form> */}
                </Modal>

            </div >
        );
    }
}
