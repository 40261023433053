import { message } from 'antd';
import { Base64 } from 'js-base64';
export default class Utils {
    
    /** 转成base64 */
    public static toBase64(obj: any) {
        return Base64.toBase64(obj);
    }
    public static getUUID(len = 32) {
        let radix = 16;
        let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
        let uuid: any = [];
        let i: any = 0;
        radix = radix || chars.length;
        if (len) {
            for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
        } else {
            var r;
            uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
            uuid[14] = '4';
            for (i = 0; i < 36; i++) {
                if (!uuid[i]) {
                    r = 0 | Math.random() * 16;
                    uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r];
                }
            }
        }
        return uuid.join('');
    }

    /** 数组删除对象 */
    public static listSplice(list: Array<any>, obj: any) {
        if (obj instanceof Function) {
            list.forEach((item, idx) => {
                if (item.toString() === obj.toString()) list.splice(idx, 1);
            });
        } else {
            let index = list.indexOf(obj);
            if (index >= 0)
                list.splice(index, 1);
        }
    }

    /** 显示信息 */
    public static showMsg(text: string, type: string = 'info') {
        message[type](text);
    }

    public static equals(obj1: any, obj2: any) {
        if (obj1 === undefined) return false;
        let keys = Object.keys(obj2);
        for (let i = 0; i < keys.length; i++) {
            if (obj1[keys[i]] !== obj2[keys[i]]) return false;
        }
        return true;
    }

    /** 根据数据生成提交的Form表单 */
    public static recordToForm(record: {}) {
        let formData = new FormData();
        Object.keys(record).map((key) => {
            let values = record[key] instanceof Object ? JSON.stringify(record[key]) : record[key];
            return formData.append(key, values);
        }, this);
        return formData;
    }

    static getInitParams() {
        let params = window.sessionStorage.getItem("ddmap_params") || '{}'
        return JSON.parse(params);
    };
    
    /**
    * 获取AppServerIP
    */
    static getAppServerIP() {
        let json = this.getInitParams();
        return json.AppServerIP;
    };

    /** 判断是否为空 */
    public static isEmpty(str: any) {
        return str === null || str === undefined || str === '';
    }

    public static eval(str: string) {
        let fn = Function;
        return new fn('return ' + str)();
    }

    private static JSCache: any = {};

    /** html模版内容替换 */
    public static htmlParse(template: string, json: any) {
        return template.replace(/{[a-z|0-9|A-Z]+}/ig, (v) => { return json[v.substring(1, v.length - 1)] || ''; });
    }

    public static inputValidator(val: any) {
        var pattern = new RegExp("[/S`~!@#$^&*()=|{}':;',\\[\\].<>/S《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？/S$]");
        if (pattern.test(val)) {
            this.showMsg('不可输入特殊字符或空格!', 'warn');
            return false;
        }
        var reg = /^[^\s]*$/;
        if (!reg.test(val)) {
            this.showMsg('输入不能包含空格!', 'warn');
            return false;
        }
        if (val.trim().length === 0) {
            this.showMsg('输入不能为空!', 'warn');
            return false;
        }
        return true;
    }

}