
import * as React from 'react';
import { Input, Form, Button, Radio, Select } from 'antd';
import './index.css';

const { Option } = Select;

interface page {

}

export default class UserShow extends React.Component<{ parent?: any }, page> {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    public render() {
        return (
            <React.Fragment>

                <div className="form-content">

                    <Form.Item label="负责人姓名" className="form-item ant-col-23" name="name">
                        <Input disabled />
                    </Form.Item>

                    <Form.Item label="负责人手机号码" className="form-item ant-col-23" name="phone" >
                        <Input disabled />
                    </Form.Item>

                    <Form.Item label="登录账号" className="form-item ant-col-23" name="userName" >
                        <Input disabled />
                    </Form.Item>

                    <Form.Item label="帐号角色" className="form-item ant-col-23" name="role" >
                        <Select disabled>
                            <Option value={Number(0)}>超级管理员</Option>
                            <Option value={Number(1)}>管理员</Option>
                            <Option value={Number(2)}>运营</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label="账号状态" className="form-radio" name="status" >
                        <Radio.Group disabled>
                            <Radio value={Number(0)}>关闭</Radio>
                            <Radio value={Number(1)}>正常</Radio>
                        </Radio.Group>
                    </Form.Item>

                </div>

                <div className="form-btn">
                    <Button onClick={() => this.props.parent.visiblePop(false)}>取消</Button>
                </div>

            </React.Fragment>
        );
    }
}