
import * as React from 'react';
import { Input, Form, Button, Radio, Select } from 'antd';
import './index.css';
import { truncate } from 'fs';

const { Option } = Select;

interface page {
}

export default class TopicAdd extends React.Component<{ parent?: any }, page> {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    public render() {
        return (
            <React.Fragment>

                <div className="form-content">

                    <Form.Item label="话题名称" className="form-item ant-col-23" name="name" rules={[{ required: true, message: '话题名称不能为空！' }, { max: 20, message: '话题不超过20个字!' }]}>
                        <Input allowClear placeholder="话题不超过20个字" />
                    </Form.Item>

                    <Form.Item label="所属分类" className="form-item ant-col-23" name="categoryId" rules={[{ required: true, message: '所属分类不能为空！' }]}>
                        <Select placeholder="请选择">
                            {this.props.parent.state.topicData?.map((e, i) => {
                                return (
                                    <Option key={e.value + 'topicId'} value={e.value}>{e.name}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item label="是否推荐" className="form-radio" name="show" rules={[{ required: true, message: '是否推荐不能为空！' }]}>
                        <Radio.Group>
                            <Radio value={true}>是</Radio>
                            <Radio value={false}>否</Radio>
                        </Radio.Group>
                    </Form.Item>

                </div>

                <div className="form-btn">
                    <Button type="primary" htmlType="submit" loading={this.props.parent.loadings}>提交</Button>
                    <Button onClick={() => this.props.parent.visiblePop(false)}>取消</Button>
                </div>

            </React.Fragment>
        );
    }
}