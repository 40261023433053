
import * as React from 'react';
import { Input, Form, Button } from 'antd';
import './index.css';

interface page {
}

export default class ActivityData extends React.Component<{ parent?: any }, page> {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    public render() {
        return (
            <React.Fragment>
                <div className="data-box">
                    {/*<Form.Item label="投放点数" className="form-item ant-col-10" name="maxPoints" >*/}
                    {/*    <Input className="del-border" />*/}
                    {/*</Form.Item>*/}

                    <Form.Item label="已用点数" className="form-item ant-col-10" name="usedPoints" >
                        <Input className="del-border" />
                    </Form.Item>

                    <Form.Item label="总扫码量" className="form-item ant-col-10" name="statScan" >
                        <Input className="del-border" />
                    </Form.Item>
                    
                    <Form.Item label="总发券数" className="form-item ant-col-10" name="statCard" >
                        <Input className="del-border" />
                    </Form.Item>

                    <Form.Item label="总核销数" className="form-item ant-col-10" name="statWriteoff" >
                        <Input className="del-border" />
                    </Form.Item>

                    <Form.Item label="总视频数" className="form-item ant-col-10" name="statVideo" >
                        <Input className="del-border" />
                    </Form.Item>

                    <Form.Item label="总播放量" className="form-item ant-col-10" name="statPlay" >
                        <Input className="del-border" />
                    </Form.Item>

                    <Form.Item label="总点赞" className="form-item ant-col-10" name="statLike" >
                        <Input className="del-border" />
                    </Form.Item>

                    <div className="form-btn">
                        <Button onClick={() => this.props.parent.visiblePop(false)}>取消</Button>
                    </div>

                </div>
            </React.Fragment>
        );
    }
}