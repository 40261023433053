import * as React from 'react';
import BusinessManagement from '../page/BusinessManagement';            //商户管理
import ActivityManagement from '../page/ActivityManagement';            //活动管理
import TopicManagement from '../page/TopicManagement';                  //话题管理
import VideoReleaseRecord from '../page/VideoReleaseRecord';            //视频发布记录
import CardRecord from '../page/CardRecord';                            //卡券记录
import UserManagement from '../page/UserManagement';                    //账号管理
import ManualCodeCancellation from '../page/ManualCodeCancellation';    //人工核销码

export default class ModuleManage {
    public static initModuleClass(name: string, parent: any) {
        switch (name) {
            case 'BusinessManagement': return <BusinessManagement parent={parent} />;
            case 'ActivityManagement': return <ActivityManagement parent={parent} />;
            case 'TopicManagement': return <TopicManagement parent={parent} />;
            case 'VideoReleaseRecord': return <VideoReleaseRecord parent={parent} />;
            case 'CardRecord': return <CardRecord parent={parent} />;
            case 'UserManagement': return <UserManagement parent={parent} />;
            case 'ManualCodeCancellation': return <ManualCodeCancellation parent={parent} />;
        }
    }
}