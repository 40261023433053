import * as React from "react";
import {useEffect, useRef, useState} from "react";

interface  IVideo{
    source:string
}
const VideoWithDuration = (props:IVideo)=>{
    const [during,setDuring] = useState(0);
    return (<div>
        <div style={{position:'absolute',top:0,zIndex:999,right:20,padding:'10px',color:'#1890ff'}}>{during}s</div>
        <video controls className="materialItem" id={'video-during'} ref={(ref:any)=>{
            console.log(ref)
            ref?.addEventListener('loadedmetadata',(e)=>{
                const durion  = e?.target?.duration;
                // @ts-ignore
                setDuring(parseInt(durion))
            })

        }}>
            <source src={props?.source} />
        </video>
    </div>)
}

export default  VideoWithDuration;