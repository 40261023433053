import * as React from 'react';
import { Button, Select, Upload, message, Pagination, Image,Checkbox,Modal } from 'antd';
import './index.css';
import axios from "axios";
import VideoWithDuration from "./videoWithDuration";

interface page {
    pageNumber:number,
    pageSize:number,
    dataSource: any,
    total: number,
    loading: boolean,
    fileList:[],
    isEdit:boolean,
    indeterminate:boolean,
    messageStatus:any,
    uploadFileList:[]
}

export default class MaterialLibrary extends React.Component<{ parent?: any }, page> {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber:1,
            pageSize:10,
            dataSource: [],
            total: 0,
            loading: false,
            fileList:[],
            isEdit:false,
            indeterminate:true,
            messageStatus:{},
            uploadFileList:[],
        }
    }

    componentDidMount() {
        this.getData({ sellerId: this.props.parent.state.id, pageNumber: this.state.pageNumber,pageSize: this.state.pageSize });
    }

    /**获取素材列表 */
    private async getData(data) {
        axios({
            url: `${process.env.REACT_APP_REMOTE_URL}/mgr/materials/list/${this.state.pageNumber}`,
            params: {
                sellerId:  this.props.parent.state.rowData.id,
                pageSize:this.state.pageSize
            },
            method: 'get',
            headers: { Authorization: sessionStorage.getItem("token") },
        }).then(res => {
            let ret = res.data;
            if (ret.success) {
                this.setState({
                    dataSource: ret.data.materials.map(item=>{ item.isCheck=0;return item; }),  //isCheck: 0默认不显示 1选中 2不选中
                    total: ret.data.total,
                })
            } else {
                message.error(ret.errroMsg);
            }
        })
    }

    //素材分页
    onPageChange(pageNumber,pageSize) {
        this.setState({
            pageNumber:pageNumber,
            pageSize:pageSize,
            isEdit:false
        },()=>{
            this.getData({ sellerId: this.props.parent?.state?.id, pageNumber: this.state.pageNumber,pageSize: this.state.pageSize });
        })
    }

    //素材选中、不选中
    onCheckboxChange=(e,index)=>{
        let data=this.state?.dataSource?.map((item,itemIndex)=>{
            if(index===itemIndex){
                item.isCheck= e.target.checked===true?1:2;
            }
            return item
        })
        this.setState({
            dataSource: data
        })
    }

    //删除前确认
    showConfirm=()=>{
        Modal.confirm({
            title: '确定删除所选素材?',
            content: '',
            okText:'确定',
            cancelText:'取消',
            onCancel:()=>{
                Modal.destroyAll();
            },
            onOk:()=>{
                this.deleteMaterial()
            },
        });
    }

    //删除素材
    deleteMaterial=()=>{
        const id=this.state.dataSource.filter(item=>{ return item.isCheck===1 }).map(item=>{return item.id});
        axios({
            url: `${process.env.REACT_APP_REMOTE_URL}/mgr/deleteMaterials`,
            data: {
                sellerId:  this.props.parent.state.rowData.id,
                materialIds: id
            },
            method: 'post',
            headers: { Authorization: sessionStorage.getItem("token") },
        }).then(res => {
            let ret = res.data;
            if (ret.isSuccess) {
                message.success('删除成功！');
                this.setState({
                    isEdit:false
                });
                this.getData({ sellerId: this.props.parent.state.id, pageNumber: 1,pageSize: 10 });
            }else{
                message.error(`${ret.errroMsg}`)
            }
        })
    }

    //文件上传
    public render() {
        const props = {
            name: 'file',
            action: `${process.env.REACT_APP_REMOTE_URL}/mgr/uploadMaterial`,
            headers: {
                authorization: `${sessionStorage.getItem("token")}`
            },
            data: {
                sellerId: this.props.parent.state.rowData.id
            },
            accept:"image/*,video/*",
            multiple:true,
            disabled:this.state.total>=100?true:false,
            // showUploadList:false,
            beforeUpload:(file,fileList)=>{
                const isLt100M = file.size / 1024 / 1024 < 100;
                const count = (100-this.state.total>=9?9:100-this.state.total)
                console.log(fileList.length > count)
                if (fileList.length > count) {
                    message.destroy();
                    message.error({
                        content:`最多上传${count}个文件!`,
                        onClose:()=>{
                            this.setState({ fileList:[] });
                        }
                    })

                    return false;
                }
                if (!isLt100M) {
                    message.error({
                        content:'视频大小不超过100MB!',
                        onClose:()=>{
                            this.setState({fileList:[]});
                        }
                    });
                }
                return isLt100M;
            },
            onChange:(info)=>{
                console.log(info);
                this.setState({fileList:info.fileList})
                if(info.file.status==='done'&&info.file.response.isSuccess&&!this.state.messageStatus[info.file.uid]){
                    const data = this.state.messageStatus;
                    data[info.file.uid] = true;
                    this.setState({messageStatus:data},()=>{
                        message.success(`${info.file.name}素材上传成功!`)
                    })
                }else if(info.file.status==='done'&&!info.file.response.isSuccess&&!this.state.messageStatus[info.file.uid]){
                    const data = this.state.messageStatus;
                    data[info.file.uid] = true;
                    this.setState({messageStatus:data},()=>{
                        message.error({
                            title:`${info.file.name}素材上传失败！`,
                            content:`${info.file.response.errroMsg}`
                        })
                    })
                }

                if(info.fileList.every(item=>item.status==='done')){
                    this.setState({
                        fileList:[],
                        pageNumber: 1
                    },()=>{
                        this.getData({ sellerId: this.props.parent?.state?.id, pageNumber: 1,pageSize: 10 });
                    })
                }
                // const fileList=info.fileList.map(item=>{
                //     let type = 3;
                //     let path = '';
                //     let id = '';
                //     let file = item.originFileObj;
                //     if(item.status==="uploading"){
                //         type = 3;//uploading
                //
                //     }else if (item.status==='done'&&item.response.isSuccess){
                //         type = item.response.data.type;
                //         path = item.response.data.path;
                //         id = item.response.data.id;
                //     }else { //error
                //         type = 4;
                //     }
                //     return { type,path,id,file,uid:item.uid}
                // });

                // let beforeData = this.state.dataSource.filter(item=>(item.type===0||item.type===1));

                // const fileListObj = info.fileList.reduce((t,item)=> {
                //     if(item.status==='done'&&item.response.isSuccess){
                //         t[item.response.data.id] = true;
                //     }
                //     return t;
                // },{});
                // beforeData = beforeData.filter(item=>!fileListObj[item.id])
                // console.log(beforeData)
                //
                // let result = [...fileList,...beforeData];
                //
                //
                // this.setState({
                //     dataSource:result.slice(0,10)
                // })
                // if(info?.fileList?.map(item=>{return item})[0].response?.isSuccess===false){
                //     message.error({
                //         title:`${info.fileList.map(item=>{return item})[0].name} 上传失败!`,
                //         content:` ${info?.fileList.map(item=>{return item})[0].response?.errroMsg}`
                //     });
                // }else{
                //     message.success(`${info.fileList.map(item=>{return item})[0].name} 上传成功!`);
                // }
            }
        };
        return (
            <React.Fragment>
                <div className="materialWrap">
                    <div style={{display:"flex",margin:"0 0 20px"}}>
                        <Upload {...props} fileList={this.state.fileList}>
                            <Button style={{marginRight:"16px"}} type="primary" >添加素材</Button>
                        </Upload>
                        <Button style={{marginRight:"16px"}} type="primary" onClick={()=>{
                            this.setState({
                                dataSource: this.state.dataSource.map(item=>{
                                    item.isCheck= this.state.isEdit===false?2:0;
                                    return item
                                }),
                                isEdit:!this.state.isEdit
                            })
                        }}>{this.state.isEdit===false?'编辑':'取消'}</Button>


                        {this.state.isEdit&& <div>
                            <Checkbox indeterminate={this.state.dataSource.some(item=>item.isCheck===1)&&!this.state.dataSource.every(item=>item.isCheck===1)} onClick={(e)=>{
                                let check=this.state.dataSource.map(item=>{
                                    item.isCheck= item.isCheck===1?2:1;
                                    return item
                                })
                                this.setState({ dataSource: check })
                            }} checked={this.state.dataSource.every(item=>item.isCheck===1)}>
                                全选
                            </Checkbox>

                            <Button style={{marginLeft:"16px"}} type="primary" onClick={()=>{
                               this.showConfirm()
                            }}>批量删除</Button>
                        </div>}
                    </div>

                    <div className="materialItemWrap">
                        {/*{*/}
                        {/*    this.state.fileList.reverse().map((item:any)=>{*/}
                        {/*      return  (<div className="materialItemBox">*/}
                        {/*            {item?.status=='done'&&item?.response?.isSuccess&&item?.response?.data.type===1&&<video src={item?.response?.data?.path} controls className="materialItem"></video>}*/}
                        {/*            {item?.status=='done'&&item?.response?.isSuccess&&item?.response?.data.type===0&&<Image src={item?.response?.data?.path} alt="素材图片" className="materialItem"/>}*/}
                        {/*           {item?.isCheck!=0&&<Checkbox checked={item?.isCheck===1?true:false} onChange={(e)=>{this.onCheckboxChange(e,index)}} className="selectedMaterial"></Checkbox>}*/}
                        {/*           {item?.status==="uploading"&&<div className="materialItem">上传中</div>}*/}
                        {/*            {item?.status==='error'&&<div className="materialItem">上传失败</div>}*/}
                        {/*        </div>)*/}
                        {/*    })*/}
                        {/*}*/}

                        { this.state.dataSource.map((item,index)=>{
                            return(
                                <div className="materialItemBox" key={item?.id}>
                                    {item.type===1&&<VideoWithDuration source={item.path}/>}
                                    {item?.type===0&&<Image src={item?.path} alt="素材图片" className="materialItem"/>}
                                    {item?.isCheck!=0&&<Checkbox checked={item?.isCheck===1?true:false} onChange={(e)=>{this.onCheckboxChange(e,index)}} className="selectedMaterial"></Checkbox>}
                                </div>
                            )
                        })}
                    </div>

                    <div style={{marginTop:"60px",textAlign:"right"}}>
                        <Pagination
                            showQuickJumper
                            showSizeChanger
                            showTotal={total => `总共 ${total} 条`}
                            current={this.state.pageNumber}
                            total={this.state.total}
                            onChange={(page:any,size:any)=>this.onPageChange(page,size)} />
                    </div>

                </div>
            </React.Fragment>
        );
    }
}