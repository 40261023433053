import * as React from 'react';
import * as Icon from '@ant-design/icons';
import { Input, Form, Table, Button, Radio, Modal, Switch, message } from 'antd';
import Request from '../../utils/Request';
import './index.css';
import Examine from './Examine';
import ActivityEntrance from './ActivityEntrance';
import ActivityData from './ActivityData';
import ActivityDetails from './ActivityDetails';
import VidoeShow from './VidoeShow';
import Utils from '../../utils/Utils';
import axios from 'axios';

/**活动管理 */
interface page {
    columns: any;
    dataSource: any;
    visible: boolean;
    modelTitle: string;
    popWidth: string,
    rowClass: string;
    rowData: any;
    loadings: boolean;
    total: number;
    size: number;
    current: number;
    searchValue: any;
    wrappedStatus: string;
    getRowData: any;
    videoData: any;
    tableHeight: number;
}

export default class ActivityManagement extends React.Component<{ parent?: any }, page> {
    private formSearch: any = React.createRef();
    private formRef: any = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    title: '活动id',
                    dataIndex: 'id',
                    key: 'id',
                    align: 'center',
                    width: '100px',
                },
                {
                    title: '活动标题',
                    dataIndex: 'actName',
                    key: 'actName',
                    align: 'center',
                    width: '140px',
                },
                {
                    title: '关联商家',
                    dataIndex: 'sellerId',
                    key: 'sellerId',
                    align: 'center',
                    width: '250px',
                    render: (text, record, index) => {
                        return (
                            <div>
                                <div><span>商家id：</span><span></span>{record.sellerId}</div>
                                <div><span>商家名称：</span><span></span>{record.sellerName}</div>
                            </div>
                        )
                    }
                },

                {
                    title: '关联卡券id',
                    key: 'couponList',
                    dataIndex: 'couponList',
                    align: 'couponList',
                    width: '250px',
                    render: (text, record, index) => {
                        return (
                            <div>
                                <div><span>卡券id：</span><span></span>{record.couponList !== null && record.couponList !== undefined && record.couponList[0] !== null ? record.couponList[0].id : ''}</div>
                                <div><span>卡券名称：</span><span></span>{record.couponList !== null && record.couponList !== undefined && record.couponList[0] !== null ? record.couponList[0].name : ''}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '视频使用数量',
                    key: 'videos',
                    dataIndex: 'videos',
                    align: 'center',
                    width: '120px',
                    render: (text, record, index) => {
                        return (
                            <div>
                                <div>总计：{record.videoSum}</div>
                                <div>已使用：{record.statCard}</div>
                                <div>剩余：{record.videoSurplus}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '活动时间',
                    key: 'dateFrom',
                    dataIndex: 'dateFrom',
                    align: 'center',
                    width: '180px',
                    render: (text, record, index) => {
                        return (
                            <div>
                                <div>{record.dateFrom}</div>
                                <div>{record.dateTo}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '启用状态',
                    key: 'enabled',
                    dataIndex: 'enabled',
                    align: 'center',
                    width: '100px',
                    render: (text, record, index) => <Switch checked={text === Number(1) ? true : false} onClick={this.switchChange} />
                },
                {
                    title: '活动状态',
                    key: 'statusName',
                    dataIndex: 'statusName',
                    align: 'center',
                    width: '90px',
                    render: (text, record, index) => {
                        if (text === '待审核') return (<span style={{ color: '#ff9800' }}>{text}</span>)
                        else if (text === '待开始') return (<span style={{ color: '#2196f3' }}>{text}</span>)
                        else if (text === '进行中') return (<span style={{ color: '#28a745' }}>{text}</span>)
                        else if (text === '已结束') return (<span style={{ color: '#9e9e9e' }}>{text}</span>)
                        else if (text === '已驳回') return (<span style={{ color: '#f44336' }}>{text}</span>)
                        else if (text === '草稿') return (<span style={{ color: '#ff9800' }}>{text}</span>)
                        else if (text === '已关闭') return (<span style={{ color: '#9e9e9e' }}>{text}</span>)
                    }
                },
                {
                    title: '操作',
                    key: 'room_code10',
                    dataIndex: 'room_code10',
                    width: '350px',
                    align: 'center',
                    fixed: 'right',
                    render: (text, record, index) => {
                        return (
                            <div className="table-col-btn">
                                <span onClick={() => { this.getRowData(record.id, true,()=>{this.visiblePop(true, '活动详情', '70%')});  }}>详情</span>
                                {record.status===1&&<span onClick={() => { this.getRowData(record.id, true,()=>{this.visiblePop(true, '审核活动') }); }}>审核活动</span>}
                                <span onClick={() => { this.getRowData(record.id,false,()=>{this.visiblePop(true, '活动入口')});  }}>入口</span>
                                {<span onClick={() => { this.getRowData(record.id,false,()=>{this.visiblePop(true, '活动数据')});  }}>活动数据</span>}
                                {record.status===3?<span onClick={() => this.jumpToVideo(record.id)}>参与明细</span>:null}
                            </div>
                        )
                    }
                },
            ],
            dataSource: [],
            visible: false,
            modelTitle: '',
            popWidth: '500px',
            rowClass: '',
            rowData: {},
            loadings: false,
            total: 0,
            size: 2,
            current: 1,
            searchValue: {},
            wrappedStatus: 'auditing',
            getRowData: {},
            videoData: [],
            tableHeight: 300,
        }
    }

    componentDidMount() {
        if (this.props.parent.state.isData) {
            this.setState({ wrappedStatus: '' });
            this.getData({ pageSize: 10, pageNo: 1, sellerId: this.props.parent.state.queryId });
        } else {
            this.getData({ pageSize: 10, pageNo: 1, wrappedStatus: this.state.wrappedStatus });
        }
        this.tableAdapt();
        window.addEventListener('resize', this.tableAdapt, { passive: false });
    }

    componentWillUnmount() {
        this.props.parent.isData(false);
        window.removeEventListener('resize', this.tableAdapt);
    }

    /**表格高度自适应 */
    tableAdapt = () => {
        let search: any = document.getElementsByClassName('search-container')[0].clientHeight + 265;
        this.setState({ tableHeight: search })
    }

    /**获取数据 */
    private async getData(data) {
        axios({
            url: `${process.env.REACT_APP_REMOTE_URL}/mgr/getActListForOperator`,
            params: data,
            method: 'get',
            headers: { Authorization: sessionStorage.getItem("token") },
        }).then(res => {
            let ret = res.data;
            if (ret.success) {
                // 卡券数量查询
                let idDatas: string = '';
                ret.data?.map(e => {
                    e.surplusPoints = e.surplusPoints === undefined || isNaN(e.surplusPoints) ? 0 : e.surplusPoints;
                    e.maxPoints = e.maxPoints === null || e.maxPoints === undefined || isNaN(e.maxPoints) ? 0 : e.maxPoints;
                    e['surplusPoints'] = e.maxPoints - e.usedPoints;
                    if (e.couponList !== null && e.couponList !== undefined && e.couponList[0] !== null && e.couponList.length !== 0) {
                        idDatas = idDatas + ',' + e.couponList[0].id
                    }
                    return true
                })
                idDatas = idDatas.substr(1, idDatas.length - 1);
                axios({
                    url: `${process.env.REACT_APP_REMOTE_URL}/mgr/seller/counts`,
                    params: { ids: idDatas },
                    method: 'get',
                    headers: { Authorization: sessionStorage.getItem("token") },
                }).then(rest => {
                    let data = rest.data
                    ret.data?.map(e => {
                        data.data?.map(j => {
                            if (e.couponList !== null && e.couponList !== undefined && e.couponList[0] !== null && e.couponList.length !== 0) {
                                if (e.couponList[0].id === j.id) {
                                    e['sendSum'] = j.send
                                    e['usedSum'] = j.used
                                }
                            }
                            return true
                        })
                        return true
                    })

                    ret.data?.map(e => {
                        // let videoSum = e.materialList !== undefined ? e.materialList.split(";") : 0;
                        // let videoCount;
                        let videoSum=e.materialList !== undefined ? e.materialList.split(";").length : 0;
                        e['videoSum'] =videoSum;
                        let left =videoSum-e.statCard;
                        if(left<0){
                            left=0;
                        }
                        e['videoSurplus'] = left;
                        return true
                    })

                    this.setState({
                        dataSource: ret.data,
                        total: ret.totalCount,
                        size: data.size,
                        current: data.current,
                    })
                })
            } else {
                this.props.parent.verification(ret.errroMsg);
                message.error(ret.errroMsg);
            }
        })
    }

    /**分页切换*/
    handleTableChange = (pagination) => {
        this.setState({
            current: pagination.current,
            size: pagination.pageSize
        }, () => {
            this.getData({ pageSize: this.state.size, pageNo: this.state.current, wrappedStatus: this.state.wrappedStatus });
        })
    };

    /**显示弹窗 */
    visiblePop(state, tit?, width?) {
        this.setState({
            popWidth: width,
            modelTitle: tit,
            visible: state,
        })
    }

    /**判断增删改查页面 */
    listContent = () => {
        if (this.state.modelTitle === '审核活动') return <Examine parent={this} />
        else if (this.state.modelTitle === '活动入口') return <ActivityEntrance parent={this} />
        else if (this.state.modelTitle === '活动数据') return <ActivityData parent={this} />
        else if (this.state.modelTitle === '活动详情') return <ActivityDetails parent={this} />
        else if (this.state.modelTitle === '视频素材') return <VidoeShow parent={this} />
    }

    /**查询 */
    onSearch = (value) => {
        for (let key in value) {
            if (value[key] === undefined || value[key] === "") {
                delete value[key];
            }
        }
        this.getData(Object.assign({ pageSize: 10, pageNo: 1, wrappedStatus: this.state.wrappedStatus }, value))
    }

    /**重置方法 */
    onReset = () => {
        this.formSearch.current.resetFields();
        this.getData({ pageSize: 10, pageNo: 1, wrappedStatus: this.state.wrappedStatus });
    }

    /**提交 */
    onFinish = async (value) => {
        value['id'] = this.state.rowData.id
        let url = `${process.env.REACT_APP_REMOTE_URL}/mgr/auditAct`;
        let ret = await Request.post(url, Utils.recordToForm(value));
        if (ret.success) {
            message.success('审核成功');
            this.getData({ pageSize: 10, pageNo: 1, wrappedStatus: this.state.wrappedStatus });
            this.visiblePop(false);
        } else {
            this.props.parent.verification(ret.errroMsg);
            message.error(ret.errroMsg);
        }
    }

    /**分类切换 */
    handleSizeChange = (value) => {
        this.setState({
            wrappedStatus: value.target.value,
        }, () => {
            this.getData({ pageSize: 10, pageNo: 1, wrappedStatus: this.state.wrappedStatus });
        })
    }
    /**视频入口 */
    jumpToVideo = (id) => {
        this.props.parent.isData(true, { type: 'videoId', id: id });
    }

    /**设置状态 */
    switchChange = (checked: any) => {
        let _this = this;
        Modal.confirm({
            title: '提示',
            icon: <Icon.ExclamationCircleOutlined />,
            centered: true,
            content: (<p>是否确认修改启用状态？</p>),
            okText: '确认',
            cancelText: '取消',
            async onOk() {
                let url = `${process.env.REACT_APP_REMOTE_URL}/mgr/enableActForOperator?id=${_this.state.rowData.id}&enable=${checked}`;
                let ret = await Request.get(url);
                if (ret.success) {
                    _this.getData({ pageSize: 10, pageNo: 1,wrappedStatus: _this.state.wrappedStatus });
                    message.success('修改成功');
                } else {
                    _this.props.parent.verification(ret.errroMsg);
                    message.error(ret.errroMsg);
                }
            },
        });
    }

    private async getRowData(id, state,callbackAfterSuccess) {
        let url = `${process.env.REACT_APP_REMOTE_URL}/mgr/getActByIdForOperator?id=${id}`;
        let ret = await Request.get(url);
        if (ret.success) {
            if (state) {
                // let data: any = ret.data.materialUrls !== undefined ? ret.data.materialUrls.split(";") : '';
                //let data:any=ret.data?.materialItemList;
                ret.data['surplusPoints'] = ret.data.maxPoints - ret.data.usedPoints;
                //debugger;
                this.setState({
                    rowData:   ret.data
                    //videoData: data
                })
            } else {
                this.setState({
                    rowData: ret.data
                }, () => {
                    if(this.formRef.current) {
                        this.formRef.current.resetFields();
                    }
                })
            }
            callbackAfterSuccess();
        } else {
            this.props.parent.verification(ret.errroMsg);
            message.error(ret?.datas?.errroMsg);
        }
    }

    public render() {
        return (
            <div className="container">

                {/* 搜索区域 */}
                <Form name="search" className="search-container" ref={this.formSearch} initialValues={{ name4: 'all', name5: 'all' }} onFinish={this.onSearch}>
                    <Form.Item label="所属商家" name="sellerName" className="search-item">
                        <Input allowClear />
                    </Form.Item>
                    <Form.Item label="活动名称" name="actName" className="search-item">
                        <Input allowClear />
                    </Form.Item>
                    <div className="search-btn ant-col-12">
                        <Button type="primary" htmlType="submit" icon={<Icon.SearchOutlined />} >查询</Button>
                        <Button icon={<Icon.ReloadOutlined />} onClick={this.onReset}>重置</Button>
                    </div>
                </Form>

                {/* 表格区域 */}
                <div className="table-container">
                    <div className="table-btn">
                        <Radio.Group value={this.state.wrappedStatus} defaultValue={this.state.wrappedStatus} onChange={this.handleSizeChange}>
                            <Radio.Button value="">全部活动</Radio.Button>
                            <Radio.Button value="doing">进行中</Radio.Button>
                            <Radio.Button value="auditing">待审核</Radio.Button>
                        </Radio.Group>
                    </div>
                    <div className="tab-container">
                        <Table
                            columns={this.state.columns}
                            dataSource={this.state.dataSource}
                            rowKey={record => record.id}
                            pagination={{ pageSize: this.state.size, current: this.state.current, total: this.state.total, showTotal: (total) => `共 ${total} 条数据` }}
                            onChange={this.handleTableChange}
                            scroll={{ y: `calc(100vh - ${this.state.tableHeight}px)`, x: 1850 }}
                            onRow={record => {
                                let videoInfo=`${record?.title}${record?.topicList?'#'+record?.topicList?.map(item=>{return item.value}).join("#"):""}`;
                                return {
                                    onClick: async () => {
                                        this.setState({
                                            rowData: {...record,videoInfo}
                                        })
                                    },
                                }
                            }}
                        />
                    </div>
                </div>

                <Modal
                    destroyOnClose={true}
                    title={this.state.modelTitle}
                    centered={true}
                    visible={this.state.visible}
                    maskClosable={false}
                    width={this.state.popWidth}
                    className="from-modal"
                    onCancel={() => {
                        this.visiblePop(false);
                    }}
                    footer={null}>
                    <Form name="form" ref={this.formRef} className="form-container" initialValues={this.state.rowData} onFinish={this.onFinish}>
                        {this.listContent}
                    </Form>
                </Modal>

            </div>
        );
    }
}
