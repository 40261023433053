
import * as React from 'react';
import { Button } from 'antd';
import './index.css';

interface page {
}

export default class VidoeShow extends React.Component<{ parent?: any }, page> {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    public render() {
        return (
            <React.Fragment>

                <div className="form-content">

                    {/* 视频素材 */}
                    <div className="video-list">
                        <div className="video-list-tit" style={{ paddingLeft: 0 }}>视频素材：</div>
                        <div className="video-list-body" style={{ marginLeft: 0 }}>
                            {this.props.parent.state.videoData.length !== 0 ? this.props.parent.state.videoData?.map((e, i) => {
                                return (
                                    <div className="video-card" key={e + i}>
                                        <video src={e} controls></video>
                                    </div>
                                )
                            }) : <span className="video-tip">暂无素材...</span>}
                        </div>
                    </div>

                </div>

                <div className="form-btn">
                    <Button onClick={() => this.props.parent.visiblePop(false)}>取消</Button>
                </div>

            </React.Fragment>
        );
    }
}