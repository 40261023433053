
import * as React from 'react';
import { Input, Form, Button, Radio } from 'antd';
import './index.css';

const { TextArea } = Input;

interface page {
    stateValue: boolean;
}

export default class Examine extends React.Component<{ parent?: any }, page> {
    constructor(props) {
        super(props);
        this.state = {
            stateValue: true
        }
    }

    onChanges = (value) => {
        this.setState({
            stateValue: value.target.value
        })
    }

    public render() {
        return (
            <React.Fragment>

                <div className="form-content">

                    <Form.Item label="审核状态" className="form-radio" name="audited" rules={[{ required: true, message: '审核状态不能为空！' }]} >
                        <Radio.Group onChange={this.onChanges}>
                            <Radio value={true}>审核通过</Radio>
                            <Radio value={false}>审核驳回</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {this.state.stateValue === false ? <Form.Item label="驳回原因" className="form-item ant-col-23" name="refuseReason" rules={[{ required: true, message: '驳回原因不能为空！' }]}>
                        <TextArea />
                    </Form.Item> : null}

                </div>

                <div className="form-btn">
                    <Button onClick={() => this.props.parent.visiblePop(false)}>取消</Button>
                    <Button type="primary" htmlType="submit">提交</Button>
                </div>

            </React.Fragment>
        );
    }
}