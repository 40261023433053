
import * as React from 'react';
import {Input, Form, Button, Radio, Descriptions, message} from 'antd';
import {IDataItem, TabMaterial} from "./TabUpload";
import {AddTopic} from "./AddTopic"
import Request from '../../utils/Request';

import './index.css';
const { TextArea } = Input;
interface page {

}

export default class ActivityDetails extends React.Component<{ parent?: any }, page> {
    private materialData: any = React.createRef();
    private actNameRef:any= React.createRef();
    private titleRef:any= React.createRef();
    private actDescriptionRef:any= React.createRef();
    // @ts-ignore
    private  videoTopicRef:any= React.createRef([]);
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    componentWillMount() {
        let actInfo=this.props.parent.state.rowData;
       this.actNameRef.current=actInfo.actName;
        this.titleRef.current=actInfo.title;
        this.actDescriptionRef.current=actInfo.actDescription;
        let materialList=actInfo.materialList;
        let filePrefix=actInfo.ossPrefix;
        let  _materialData=[];
        if(materialList ){
           //debugger;
            _materialData=materialList.split(";")
            this.materialData.current=_materialData;
            // this.setState({materialData:_materialData})
        }else{
            this.materialData.current=[];
        }

    }


    private  onMaterialChanged(data){
       if(data){
           let _materialData=[];
           data.map(item=>{
               // @ts-ignore
               _materialData.push(item.path);
           })
           this.materialData.current=_materialData;
          // this.setState({materialData:_materialData})
           console.log("materialData.length:"+_materialData.length);
       }

    }

     private async saveAct(){

        let actInfo=this.props.parent.state.rowData;


         let videoTopicStr = '';
         let videoTopicArr = [];
         let topicList=this.videoTopicRef.current;
         if(!topicList){
             topicList= this.convertTopics(actInfo.topicList)
         }
         if(topicList){
             for( let item of topicList ){
                 // @ts-ignore
                 videoTopicArr.push( item.id );
             }
         }
         videoTopicStr = videoTopicArr.join(',');
         let params= {
                "id":actInfo.id,
            "actName":this.actNameRef.current,
            "title":this.titleRef.current,
            "topic": videoTopicStr,
            "actDescription":this.actDescriptionRef.current,
            "materials":this.materialData.current
            };
        let ret = await Request.jsonPost(`${process.env.REACT_APP_REMOTE_URL}/mgr/activity/save`, JSON.stringify(params));
        if (ret.success) {
            message.success('保存成功');
        } else {
            message.error(ret.errroMsg);

        }
    }

    // private  async getActDetail(id) {
    //     let url = `${process.env.REACT_APP_REMOTE_URL}/mgr/getActByIdForOperator?id=${id}`;
    //     let ret = await Request.get(url);
    //     if (ret.success) {
    //         return ret.data;
    //     } else {
    //         message.error(ret?.datas?.errroMsg);
    //         return null;
    //     }
    // }


    convertToTabUploadDataSource(){
        let ds=[];
        if(this.materialData.current) {
            this.materialData.current.map(item => {
                // @ts-ignore
                ds.push({
                    "path": item,
                    "type": 1
                });
            });
        }
        return ds;

    }

    selectTopics(topics:any){
       // this.setState({videoTopic: topics});
        this.videoTopicRef.current=topics;
    }


    convertTopics(topicList:Array<any>){
        let result:any = [];
        if( topicList ){
            for( let item of topicList ){
                result.push({id: item.key, name: item.value});
            }
        }
        return result;
    }




    public render() {
        let actInfo=this.props.parent.state.rowData;
        console.log('act detail rendering...,actId:'+actInfo.id);
        //debugger;
        //生成上传组件的数据
        let ds=this.convertToTabUploadDataSource();
        let filePrefix=actInfo.ossPrefix;
        console.log('ds.length::'+   ds.length);
        return (

            <React.Fragment>

                <div className="form-content">

                    <div className="form-titlt">活动</div>

                    <table>
                        <tbody>
                        <tr>
                            <td>
                                <Form.Item label="活动id" className="form-item ant-col-11 actitem " name="id" >
                                    <Input readOnly />
                                </Form.Item>
                            </td>
                            <td>
                                <Form.Item label="关联商家id" className="form-item ant-col-11 actitem" name="sellerId" >
                                    <Input readOnly />
                                </Form.Item>
                            </td>
                        </tr>

                        <tr>
                            <td colSpan={2}>
                                <Form.Item label="活动标题" className="form-item ant-col-22 actitem" name="actName" >
                                    <Input     onChange={(e)=>{this.actNameRef.current=e.target.value}}/>
                                </Form.Item>
                            </td>
                        </tr>

                        <tr>
                            <td colSpan={2}>
                                <Form.Item label="视频标题"  className="form-item ant-col-22 actitem-bigline" name="title" >
                                    <Input   onChange={(e)=>{this.titleRef.current=e.target.value}} />
                                </Form.Item>
                                <Form.Item label="话题"  className="form-item ant-col-22 actitem-bigline"   >
                                    <AddTopic title={actInfo.title} data={this.convertTopics(actInfo.topicList)} onChange={(topics:any)=>this.selectTopics(topics)} sellerId={actInfo.sellerId} />
                                </Form.Item>
                            </td>
                        </tr>




                    {/*{this.props.parent.state.rowData.actType===1&&<Form.Item label="投放点数" className="form-item ant-col-11" name="maxPoints" >*/}
                    {/*    <Input readOnly />*/}
                    {/*</Form.Item>}*/}

                    {/*{this.props.parent.state.rowData.actType===1&&<Form.Item label="剩余点数" className="form-item ant-col-11" name="surplusPoints" >*/}
                    {/*    <Input readOnly />*/}
                    {/*</Form.Item>}*/}






                        <tr>
                        <td colSpan={2}>
                            <Form.Item label="活动介绍" className="form-item ant-col-22 actitem" name="actDescription" >
                                <TextArea  onChange={(e)=>{this.actDescriptionRef.current=e.target.value}}/>
                            </Form.Item>
                        </td>
                       </tr>


                    {/* 视频素材 */}
                    {/*<div className="video-list" style={{ height: `${this.props.parent.state.videoData.length !== 0 ? '250px' : '60px'}` }}>*/}
                    {/*    <div className="video-list-tit">视频素材：</div>*/}
                    {/*    <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>*/}
                            {/*{this.props.parent.state?.videoData?.length !== 0 ? this.props.parent.state?.videoData?.map((e, i) => {*/}
                            {/*    return (*/}
                            {/*        <div className="video-card" key={e + i}>*/}
                            {/*            {e.type==1&&<video src={e.path} controls></video>}*/}
                            {/*            {e.type==0&&<img src={e.path} alt="视频素材" width={188} height={185}/>}*/}
                            {/*        </div>*/}
                            {/*    )*/}
                            {/*}) : <span style={{ color: '#b1b0b0' }}>暂无素材...</span>}*/}
                    <tr>

                        <td colSpan={2}>
                            <Form.Item label="待分发视频列表:" className="form-item ant-col-22 actitem"  >
                                <TabMaterial
                                    dataSource={[...ds]}
                                    filePrefix={filePrefix}
                                    // tabIndex={'1'}
                                    onChange={(data: IDataItem[]) => {
                                        this.onMaterialChanged(data);
                                    }}
                                    requireMaterialNumPermit={-1}
                                    uploadVideOnly={ true }
                                    maxMaterialNumPermit={  500 }
                                />
                            </Form.Item>
                        </td>

                    </tr>

                        </tbody>
                    </table>
                    {/*    </div>*/}
                    {/*</div>*/}



                    <div className="form-titlt">卡券</div>

                    {/*<Form.Item label="关联卡券id" className="form-item ant-col-22" name="couponIdList" >*/}
                    {/*    <Input readOnly />*/}
                    {/*</Form.Item>*/}

                    {/* <Form.Item label="总投放" className="form-item ant-col-11" name="51">
                        <Input readOnly />
                    </Form.Item> */}

                    <Form.Item label="已发放" className="form-item ant-col-11" name="sendSum" >
                        <Input readOnly />
                    </Form.Item>


                    <Form.Item label="已核销" className="form-item ant-col-11" name="usedSum" >
                        <Input readOnly />
                    </Form.Item>
                    {/* <Form.Item label="可领取" className="form-item ant-col-11" name="54" >
                        <Input readOnly />
                    </Form.Item> */}

                </div>

                <div className="form-btn">
                    <Button onClick={() => this.saveAct()}>保存修改</Button>

                    <Button onClick={() => this.props.parent.visiblePop(false)}>取消</Button>
                </div>

            </React.Fragment>
        );
    }
}