
import * as React from 'react';
import { Input, Form, Button, Radio, Select, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './index.css';
import Request from '../../utils/Request';

const { Option } = Select;

interface page {

}

export default class UserEdit extends React.Component<{ parent?: any }, page> {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    /**密码重置 */
    resetPassword = () => {
        let _this = this;
        Modal.confirm({
            title: '密码重置提示',
            icon: <ExclamationCircleOutlined />,
            centered: true,
            content: (<p>是否确认重置密码？</p>),
            async onOk() {
                let url = `${process.env.REACT_APP_REMOTE_URL}/mgr/account/password/rest/${_this.props.parent.state.rowData.id}`;
                let ret = await Request.post(url, '');
                if (ret.success) {
                    message.success('重置成功')
                } else {
                    message.error(ret.errroMsg);
                    _this.props.parent.props.parent.verification(ret.errroMsg);
                }
            }
        })
    }

    public render() {
        //debugger;
        return (
            <React.Fragment>

                <div className="form-content">

                    <Form.Item label="负责人姓名" className="form-item ant-col-23" name="name" rules={[{ required: true, message: '负责人姓名不能为空！' }]}>
                        <Input allowClear />
                    </Form.Item>

                    <Form.Item label="负责人手机号码" className="form-item ant-col-23" name="phone" rules={[{ required: true, message: '负责人姓名不能为空！' }]}>
                        <Input allowClear />
                    </Form.Item>

                    <Form.Item label="登录账号" className="form-item ant-col-23" name="userName" rules={[{ required: true, message: '登录账号不能为空！' }]}>
                        <Input allowClear placeholder="将作为商户登录账号使用" />
                    </Form.Item>

                    <Form.Item label="登录密码" className="form-item resetPassword ant-col-23" >
                        <Input disabled placeholder="******" className="ant-col-14" />
                        <Button onClick={this.resetPassword}>密码重置</Button>
                    </Form.Item>


                    {this.props.parent.state.rowData.role!=1?
                    <Form.Item label="帐号角色" className="form-item ant-col-23" name="role" rules={[{ required: true, message: '运营人员不能为空！' }]}>
                        <Select placeholder="请选择">
                            {/* <Option value={Number(0)}>超级管理员</Option> */}
                            {/* <Option value={Number(1)}>管理员</Option> */}
                            <Option value={Number(2)}>运营</Option>
                        </Select>
                    </Form.Item>:<></>}

                    <Form.Item label="账号状态" className="form-radio" name="status" rules={[{ required: true, message: '账号状态不能为空！' }]}>
                        <Radio.Group>
                            <Radio value={Number(1)}>正常</Radio>
                            <Radio value={Number(0)}>关闭</Radio>
                        </Radio.Group>
                    </Form.Item>

                </div>

                <div className="form-btn">
                    <Button type="primary" htmlType="submit" loading={this.props.parent.loadings}>提交</Button>
                    <Button onClick={() => this.props.parent.visiblePop(false)}>取消</Button>
                </div>

            </React.Fragment >
        );
    }
}